@tailwind base;
@layer base {
  h1 {
    @apply text-2xl;
  }
  h2 {
    @apply text-xl;
  }
  h3 {
    @apply text-lg;
  }
  a {
    @apply text-blue-600 hover:underline;
  }
  input {
    @apply bg-transparent;
  }
}
@tailwind components;
@tailwind utilities;

html,
body {
  height: 100vh;
  --color-border-primary: #e2e8f0;
  --color-bg-secondary: #f1f5f9;
}

#root {
  height: 100%;
}

.ql-editor {
  min-height: 150px;
}

.light {
  --color-bg-primary: #ffffff;
  --color-bg-secondary: #f1f5f9;
  --color-bg-tertiary: #e1e4e9;
  --color-text-primary: #000000;
  --color-text-secondary: #5c759d;
  --color-text-tertiary: #929aa1;
  --color-text-heading: #3d434d;
  --color-text-bg-button: #4862b1;
  --color-bg-highlight: #dbeafe;
  --color-bg-highlight-secondary: #dbeafe;
  --color-border-primary: #e2e8f0;
  --color-bg-button-primary: #4862b1;
  --color-bg-button-secondary: #243b8a;
  --color-icon-button-primary: #102053;
  --color-icon-button-secondary: #94a3b8;
  --color-shadow-primary: #c0c9d5;
}

.dark {
  --color-bg-primary: #17171e;
  --color-bg-secondary: #20222c;
  --color-bg-tertiary: #2e3641;
  --color-text-primary: #ccd4df;
  --color-text-secondary: #6a90cd;
  --color-text-tertiary: #929aa1;
  --color-text-heading: #ffffff;
  --color-text-bg-button: #4862b1;
  --color-bg-highlight: #20222c;
  --color-bg-highlight-secondary: #4862b1;
  --color-border-primary: #29303a;
  --color-bg-button-primary: #4862b1;
  --color-bg-button-secondary: #243b8a;
  --color-icon-button-primary: #cbd5e1;
  --color-icon-button-secondary: #3c55af;
  --color-shadow-primary: #000000;
}
